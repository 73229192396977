<template>
  <div class="shopping com-overflow-hidden">
    <div class="content home-width com-margin-center" style="">
      <div class="cartmain">
        <div class="cart-table-th com-flex" style="display: flex">
          <!-- <div class="th th-chk"><input id="J_SelectAllCbx1" type="checkbox" v-model="isAllSelected"
                            @click="AllSelectedClick()" v-show="shopItems.length !== 0" /><label
                            for="J_SelectAllCbx1">全选</label></div> -->
          <div
            style="
              display: grid;
              grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
              width: 100%;
            "
          >
            <div class="th th-info">商品信息</div>
            <div class="th th-price">单价</div>
            <div class="th th-amount">数量</div>
            <div class="th th-amount">单位</div>
            <div class="th th-amount">体积/m³</div>
            <div class="th th-sum">金额</div>
            <div class="th th-amount">类型</div>
            <div class="th th-op">操作</div>
          </div>
        </div>
        <div class="cart-list">
          <div class="default-list" v-if="!shopItems.length">您的购物车是空的~</div>
          <ShopItem
            v-for="(item, index) in shopItems"
            :key="index"
            :shopIt="item"
            @BackFather="sumChange"
            @BackFathers="sumChanges"
          >
          </ShopItem>
        </div>
      </div>
      <div class="ft"></div>
      <el-dialog title="下单详情" :visible.sync="dialogTableVisible" width="1400px">
        <shopOrder ref="shopCarts"></shopOrder>
      </el-dialog>
      <div style="display: flex; justify-content: space-between">
          <div></div>
          <div>
            <el-button type="success">加入购物车</el-button>
            <el-button type="primary" @click="dialogTableVisiblep = true"
              >退出</el-button
            >
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ShopItem from "../Shoping/ShopItem.vue";
import shopOrder from "../shopOrder/index.vue";

export default {
  name: "ShoppingVue",
  data() {
    return {
      isHome: false,
      isActive: false,
      isAllSelected: false,
      allShop: "0.00",
      dialogTableVisible: false,
      shopItems: [
        {
          type: "现货",
          checked: false,
          type: 1,
          img:
            "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
          content: "CHRISBELLA-双手腕女包-BG012211222",
          price: 4100,
          num: 300,
          id: "62371932",
          show: false,
        },
        {
          type: "在途预售",
          checked: false,
          type: 2,
          img:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
          content: "BAGCO-单手腕女包-EK012212003",
          price: 4100,
          num: 300,
          id: "62371932",
          show: false,
        },
      ],
    };
  },
  components: {
    ShopItem,
    shopOrder,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {},
  watch: {
    shopItems: {
      deep: true,
      immediate: true,
      handler(n) {
        let sum = 0;
        for (const item of n) {
          if (item.isSelected) {
            this.isActive = true;
            return;
          }
        }
        this.isActive = false;
      },
    },
  },
  methods: {
    sumChange(a, b) {
      console.log("3213123");
      console.log(a, b);
      if (a == 1) {
        this.allShop = this.allShop * 1 + b * 1;
      } else {
        this.allShop = this.allShop * 1 - b * 1;
      }
    },
    sumChanges() {
      // if(this.isAllSelected)
      this.allShop = 0;

      this.shopItems.forEach((item) => {
        item.checked = true;
        this.allShop = item.price * 1 * item.num * 1 + this.allShop * 1;
      });
    },
    deleteMultipe() {
      this.dialogTableVisible = true;
    },
    AllSelectedClick() {
      // if(this.isAllSelected)
      this.allShop = 0;
      if (this.isAllSelected == false) {
        this.isActive = true;
        this.shopItems.forEach((item) => {
          item.checked = true;
          this.allShop = item.price * 1 * item.num * 1 + this.allShop * 1;
        });
      } else {
        this.isActive = false;
        this.shopItems.forEach((item) => {
          item.checked = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
 .add {
        a {
          width: 180px;
          background-color: #f40;
          padding: 0 25px;
          font-size: 16px;
          color: #fff;
          height: 36px;
          line-height: 36px;
          text-align: center;
          display: inline-block;
          border-radius: 3px;
        }

        .buy {
          width: 134px;
          color: #e5511d;
          border-color: #f0cab6;
          background: #ffe4d0;
          margin-right: 20px;
          padding: 0;
        }
      }
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}

.shopping {
  background: repeat-y
    url("https://www.gqedu.org.cn:6868/manage/716bbd2b57c841ccbac91d12a4ea907d.png");
  background-size: cover;

  .content {
    background-color: #fff;

    margin-bottom: 45px;
    font-family: "Microsoft YaHei";
    padding-bottom: 10px;
  }
}

.hd,
.cart-table-th {
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
}

.hd {
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .car {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .cart-sum {
    strong {
      font-size: 22px;
      font-weight: 700;
      color: #f50;
      margin-right: 12px;
    }

    a {
      $h: 42px;
      display: inline-block;
      text-align: center;
      line-height: $h;
      @include wh(74px, $h);
      background: #aaa;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      cursor: not-allowed;
    }

    .active {
      background-color: #f50;
      cursor: pointer;
    }
  }
}

.cartmain {
  user-select: none;
  $h: 50px;

  .cart-table-th {
    padding: 0 25px;
    height: $h;
    margin-bottom: 15px;
  }

  .th {
    height: 100%;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    line-height: $h;
  }

  .default-list {
    color: #aaaaaa;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 300px;
  }
}
</style>
<style lang="scss">
.th {
  flex: 2;
}

.th-chk {
  flex: 1.2;

  // width: 150px;
  input,
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    margin-right: 8px;
  }

  label {
    width: 50px;
    height: 50px;
    display: inline-block;
  }
}

.th-info {
  flex: 2.8;
}

.th-price,
.th-amount,
.th-sum,
.th-op {
  text-align: center;
}

</style>
