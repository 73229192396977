<template>
    <div class="shopitem com-flex" >



        <div style="display: flex;">
            <div style="display:flex;width:304px">
                <img :src="shopIt.img" style="height: 80px;width:80px" />
                <p>{{ shopIt.content }}</p>
                <div class="icon com-flex">
                    <!-- <img src="@images/pic_029.png" />
          <img src="@images/pic_030.png" />
          <img src="@images/pic_031.png" /> -->
                </div>
            </div>
            <div style="width:231px;text-align: center;"><span>&yen;</span>{{ shopIt.price }}</div>
            <div style="width:231px;text-align: center;"> <el-input-number v-model="shopIt.num" @change="handleChange"
                    :min="1" label="描述文字"></el-input-number></div>
            <div style="width:231px;text-align: center;"><span>&yen;</span>{{ total }}</div>
            <div style="width:231px;text-align: center;">
                <span @click="deleteOne(shopIt.id)">删除</span>
                <span @click="shopIt.show = !shopIt.show" v-if="shopIt.type == 1">价格详情</span>
                <span @click="shopIt.show = !shopIt.show" v-if="shopIt.type == 2">数量详情</span>
            </div>
        </div>
        <div style="width: 76.5%;
      margin-left: 23.5%;" v-if="shopIt.show == true && shopIt.type == 1">
            <el-table :data="tableData" border>
                <el-table-column prop="date" align="center" label="起订量" width="130">
                </el-table-column>
                <el-table-column prop="name" align="center" label="箱单价/人民币" width="130">
                </el-table-column>
                <el-table-column prop="address" align="center" label="颜色配比" width="480">
                </el-table-column>
            </el-table>
        </div>
        <div style="display:flex;flex-wrap: wrap;" v-if="shopIt.show == true && shopIt.type == 2">
            <div style="margin-right: 10px;margin-top: 10px">橙色：<el-input-number v-model="num1" :min="1" :max="1000"
                    label=""></el-input-number></div>
            <div style="margin-right: 10px;margin-top: 10px">粉色：<el-input-number v-model="num2" :min="1" :max="1000"
                    label=""></el-input-number></div>
            <div style="margin-right: 10px;margin-top: 10px">黑色：<el-input-number v-model="num3" :min="1" :max="1000"
                    label=""></el-input-number></div>
            <div style="margin-right: 10px;margin-top: 10px">黄色：<el-input-number v-model="num4" :min="1" :max="1000"
                    label=""></el-input-number></div>
            <div style="margin-right: 10px;margin-top: 10px">蓝色：<el-input-number v-model="num5" :min="1" :max="1000"
                    label=""></el-input-number></div>
            <div style="margin-right: 10px;margin-top: 10px">绿色：<el-input-number v-model="num6" :min="1" :max="1000"
                    label=""></el-input-number></div>
            <div style="margin-right: 10px;margin-top: 10px">玫红：<el-input-number v-model="num7" :min="1" :max="1000"
                    label=""></el-input-number></div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'ShopItem',
    data() {
        return {
            sonShowNumber: 0,
            num1: 10,
            num2: 10,
            num3: 10,
            num4: 10,
            num5: 10,
            num6: 10,
            num7: 10,
            shopItem: {},
            tableData: [{
                date: '1',
                name: '4500',
                address: '橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3'
            }, {
                date: '100',
                name: '4300',
                address: '橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3'
            }, {
                date: '300',
                name: '4100',
                address: '橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3'
            }, {
                date: '600',
                name: '3800',
                address: '橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3'
            }]
        }
    },
    props: {
        shopIt: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        total() {
            const result = this.shopIt.num * this.shopIt.price
            return result.toFixed(2)
        }
    },
    methods: {
        handleChange() {
           
                this.$emit('BackFathers', 1, this.shopIt.num * this.shopIt.price)
          
        },

        sub() {
            if (this.shopItem.num <= 1) {
                this.shopItem.num = 1
                return
            }
            this.subProd(this.shopItem.id, this.shopItem)
            // this.shopItem.num--;
        },
        add() {
            this.addProd(this.shopItem)
            // this.shopItem.num++;
        },
        changeStatus() {
        
                console.log(2)
                this.$emit('BackFather', 2, this.total)
          

        }
    }
}
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
    width: $w;
    height: $h;
}

.shopitem {
    // height: 117px;
    margin: 25px 24px;
    background-color: #f5f5f5;
    border-radius: 18px;
    border: 1px solid #f7f9fa;
    padding: 20px;
    box-sizing: border-box;

    .th-chk {
        input {
            margin-top: -50%;
            transform: translateY(50%);
        }

        img {
            @include wh(80px);
        }
    }

    .th-info {
        .icon {
            margin-top: 40px;
            width: 16px;

            img {
                margin-right: 6px;
            }
        }
    }

    .th-price,
    .th-sum {
        font-weight: 700;
        font-size: 14px;
    }

    .th-price {
        color: #3c3c3c;
        margin-top: 6px;
        margin-left: 10px;
    }

    .th-sum {
        color: #f50;
    }

    .th-amount {
        height: 25px;
        margin-left: 24px;

        span {
            width: 19px;
            line-height: 25px;
            border: 1px solid #e5e5e5;
            background: #f0f0f0;
            font-size: 16px;
        }

        input {
            width: 39px;
            height: 100%;
            border: 1px solid #aaa;
            color: #343434;
            text-align: center;
            outline: none;
        }

        .sub:hover,
        .add:hover {
            color: #f50;
            border-color: #f60;
        }
    }

    .th-op {
        margin-top: 5px;

        span:hover {
            cursor: pointer;
            color: #f50;
            text-decoration: underline;
        }
    }

    &:hover {
        border-color: #ff915e;
    }
}
</style>