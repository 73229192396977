<template>
  <div style="border: 1px solid #bfbfbf; margin: 21px">
    <div
      style="background: #f1f1f1; display: flex; line-height: 50px; padding-left: 44px"
    >
      <div style="font-size: 15px; font-weight: 700">添加人:76432642480480949242348</div>
      <div style="margin-left: 10px">添加时间:2024-04-09</div>
    </div>
    <div class="shopitem com-flex" style="display: flex; width: 100%">
      <div>
        <div
          style="
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            width: 100%;
          "
        >
          <div class="th th-chk">
            <input
              id="J_SelectAllCbx1"
              @click="changeStatus"
              type="checkbox"
              v-model="shopIt.checked"
            />
            <img :src="shopIt.img" />
          </div>
          <div class="th th-info">
            <p>{{ shopIt.content }}</p>
            <div class="icon com-flex">
              <!-- <img src="@images/pic_029.png" />
        <img src="@images/pic_030.png" />
        <img src="@images/pic_031.png" /> -->
            </div>
          </div>
          <div class="th th-price"><span>&yen;</span>{{ shopIt.price }}</div>
          <div class="th th-amount com-flex-center" style="display: flex;justify-content: center;">
            <el-input-number
              v-model="shopIt.num"
              @change="handleChange"
              :min="1"
              label="描述文字"
            ></el-input-number>
          </div>
          <div class="th th-price">箱</div>
          <div class="th th-price">100</div>
          <div class="th th-sum"><span>&yen;</span>{{ total }}</div>
          <div class="th th-price">现货</div>
          <div class="th th-op">
            <span @click="deleteOne(shopIt.id)">删除</span>
            <span @click="shopIt.show = !shopIt.show" v-if="shopIt.type == 1"
              >价格详情</span
            >
            <span @click="shopIt.show = !shopIt.show" v-if="shopIt.type == 2"
              >数量详情</span
            >
          </div>
        </div>
        <div
          style="width: 76.5%; margin-left: 23.5%"
          v-if="shopIt.show == true && shopIt.type == 1"
        >
          <el-table :data="tableData" border>
            <el-table-column prop="date" align="center" label="起订量" width="130">
            </el-table-column>
            <el-table-column prop="name" align="center" label="箱单价/人民币" width="130">
            </el-table-column>
            <el-table-column prop="address" align="center" label="颜色配比" width="480">
            </el-table-column>
          </el-table>
        </div>
        <div
          style="display: flex; flex-wrap: wrap"
          v-if="shopIt.show == true && shopIt.type == 2"
        >
          <div style="margin-right: 10px; margin-top: 10px">
            橙色：<el-input-number
              v-model="num1"
              :min="1"
              :max="1000"
              label=""
            ></el-input-number>
          </div>
          <div style="margin-right: 10px; margin-top: 10px">
            粉色：<el-input-number
              v-model="num2"
              :min="1"
              :max="1000"
              label=""
            ></el-input-number>
          </div>
          <div style="margin-right: 10px; margin-top: 10px">
            黑色：<el-input-number
              v-model="num3"
              :min="1"
              :max="1000"
              label=""
            ></el-input-number>
          </div>
          <div style="margin-right: 10px; margin-top: 10px">
            黄色：<el-input-number
              v-model="num4"
              :min="1"
              :max="1000"
              label=""
            ></el-input-number>
          </div>
          <div style="margin-right: 10px; margin-top: 10px">
            蓝色：<el-input-number
              v-model="num5"
              :min="1"
              :max="1000"
              label=""
            ></el-input-number>
          </div>
          <div style="margin-right: 10px; margin-top: 10px">
            绿色：<el-input-number
              v-model="num6"
              :min="1"
              :max="1000"
              label=""
            ></el-input-number>
          </div>
          <div style="margin-right: 10px; margin-top: 10px">
            玫红：<el-input-number
              v-model="num7"
              :min="1"
              :max="1000"
              label=""
            ></el-input-number>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopItem",
  data() {
    return {
      sonShowNumber: 0,
      num1: 10,
      num2: 10,
      num3: 10,
      num4: 10,
      num5: 10,
      num6: 10,
      num7: 10,
      shopItem: {},
      tableData: [
        {
          date: "1",
          name: "4500",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
        {
          date: "100",
          name: "4300",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
        {
          date: "300",
          name: "4100",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
        {
          date: "600",
          name: "3800",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
      ],
    };
  },
  props: {
    shopIt: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    total() {
      const result = this.shopIt.num * this.shopIt.price;
      return result.toFixed(2);
    },
  },
  methods: {
    handleChange() {
      if (this.shopIt.checked == true) {
        this.$emit("BackFathers", 1, this.shopIt.num * this.shopIt.price);
      }
    },

    sub() {
      if (this.shopItem.num <= 1) {
        this.shopItem.num = 1;
        return;
      }
      this.subProd(this.shopItem.id, this.shopItem);
      // this.shopItem.num--;
    },
    add() {
      this.addProd(this.shopItem);
      // this.shopItem.num++;
    },
    changeStatus() {
      if (this.shopIt.checked == false) {
        console.log(1);
        this.$emit("BackFather", 1, this.total);
      } else {
        console.log(2);
        this.$emit("BackFather", 2, this.total);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}
.el-input-number {
  width: 128px;
}
.shopitem {
  // height: 117px;
  margin: 25px 0;
  border-radius: 18px;
  border: 1px solid #f7f9fa;
  padding: 10px;
  box-sizing: border-box;

  .th-chk {
    input {
      margin-top: -50%;
      transform: translateY(50%);
    }

    img {
      @include wh(80px);
    }
  }

  .th-info {
    .icon {
      margin-top: 40px;
      width: 16px;

      img {
        margin-right: 6px;
      }
    }
  }

  .th-price,
  .th-sum {
    font-weight: 700;
    font-size: 14px;
  }

  .th-price {
    color: #3c3c3c;
    margin-top: 9px;
   
  }

  .th-sum {
    color: #f50;
    margin-top: 9px;
  }

  .th-amount {
    height: 25px;
  

    span {
      width: 19px;
      line-height: 25px;
      border: 1px solid #e5e5e5;
      background: #f0f0f0;
      font-size: 16px;
    }

    input {
      width: 39px;
      height: 100%;
      border: 1px solid #aaa;
      color: #343434;
      text-align: center;
      outline: none;
    }

    .sub:hover,
    .add:hover {
      color: #f50;
      border-color: #f60;
    }
  }

  .th-op {
    margin-top: 9px;

    span:hover {
      cursor: pointer;
      color: #f50;
      text-decoration: underline;
    }
  }

  &:hover {
    border-color: #ff915e;
  }
}
</style>
